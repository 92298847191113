@import '../../../../../../../styles/base/color';

.descuentos-cuenta {
  p {
    font-size: 0.85rem !important;
    color: $fontTertiary !important;
    padding: 0 0 5px !important;
  }
}
.codigo-descuento {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;

  &__title {
    font-size: 0.85rem !important;
    color: $fontTertiary !important;
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
    }
  }

  &__container-input-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    padding-left: 6px;

    input[type=text] {
      height: 31px;
      padding: 0px 5px !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      font-size: 0.8rem !important;
      font-weight: 500 !important;
      border-color: $primary;
    }
    
    button {
      height: 31px !important;
      width: 64px;
      font-size: 0.7rem !important;
      background-color: $primary;
      color: $white;
      border: 1px solid $primary;
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      padding: 0 8px 0 7px;
      position: relative;
    }
  }

  &__aplicado {
    color: $checkBoxCheked !important;
    font-weight: 600;
  }

}