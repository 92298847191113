@import '../../../../../../../styles/base/color';
@import '../../../../../../../styles/base/breakpoints';
@import '../../../../../../../styles/mixins/mixins';

.simple-spinner {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 77px;
  top: 15px;
  transform: translate(-50%, -50%);
}

.simple-spinner span {
  display: block;
  width: 25px;
  height: 25px;
  border: 3px solid transparent;
  border-radius: 50%;
  border: 2px;
  border-right: 1px solid $fontTertiary;
	opacity: 0.8;
  animation: spinner-anim 0.8s linear infinite;
}

.simple-spinner-autocomplete-poblaciones {
  left: 70%;
  top: 18px;

  @include sm {
    left: 80%;
  }

  @include lg {
    left: 60%;
  }

  @include xl {
    left: 70%;
  }
}

.spinner-codigo-descuento {
  left: 30px;
  top: 13px;
}

.spinner-codigo-descuento span {
  border-color: $primaryLight;
  width: 22px;
  height: 22px;
}

.spinner-pedido-section {
  width: 25px !important;
  height: 25px !important;
  top: 18px;
  left: 49%;
}

.spinner-pedido-section span, .spinner-transferencia-form span {
  border-color: $primaryLight;
}

.spinner-dir-envio {
  top: 48%;
  left: 90%;
}

.spinner-transferencia-form{
  top: 45%;
  left: 50%;
}

@keyframes spinner-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}