@import '../../../../../styles/base/color';
@import '../../../../../styles/mixins/mixins';

.codigo-aut-form {
  padding: 16px 6px 6px;
  input[type=text] {
    height: 42px !important;
  }

  form {
    padding: 0 6px;
  }
}