@import '../../../../../styles/base/color';
@import '../../../../../styles/base/breakpoints';
@import '../../../../../styles/mixins/mixins';

.metodos-pago {
    @include flexRow(flex-start, center);
    padding: 20px 6px 10px;
    gap: 10px;

    @include custom(420px) {    
        gap: 25px;
    }

    @include custom(1300px) {
        gap: 10px;
    }

    &__card {
        width: 63px;

        @include custom(1300px) {
            width: 100px;
        }

        div {
            @include flexCol(center, center);
            height: 63px;
            width: 63px;
            box-shadow: 3px 3px 25px $boxShadow;
            border-radius: 10px;
            position: relative;
            cursor: pointer;

            @include custom(1300px) {
                height: 80px;
                width: 80px;
            }

            span {
                @include flexCol(center, center);
                width: 100%;
                height: 100%;

                svg {
                    font-size: 24px;
                    color: $fontTertiary;

                    @include custom(1300px) {
                        font-size: 32px;
                    }
                }
            }

            .icon-check {
                display: none;
            }
        }

        p {
            font-size: 9px !important;
            padding-top: 8px;
            line-height: 12px;

            @include xl {
                font-size: 12px !important;
            }
        }

        &.selected {

            div {
                border: 1px solid $primary;

                .icon-check {
                    @include primaryText(1.3rem);
                    position: absolute;
                    top: -6px;
                    right: -6px;
                    background-color: $white;
                    border-radius: 50%;
                    display: flex;
                }
            }
        }

        &--visa-img {
            width: 28px;
            height: 34px;

            @include custom(1300px) {
                width: 38px;
                height: 48px;
            }
        }
        
        &--bizum-img {
            width: 48px;
            height: 13px;

            @include custom(1300px) {
                width: 65px;
                height: 18px;
            }
        }
    }

}

.alert-pago-efectivo {
    background-color: $background !important;
    color: $primary !important;
    border-color: $primary !important;

    h4 {
        font-size: 16px !important;
    }

    p {
        font-size: 14px !important;
    }
}