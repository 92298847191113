@import '../../../styles/base/color';
@import '../../../styles/mixins/mixins';

body{ overflow: auto !important; }

.resume {
    ul, li { list-style: square !important; }
}

.circle-done {
    display: inline-block;
    padding: 15px 17px;
    background-color: $primaryLight;
    border-radius: 50%;

    &.border { border: 1px solid $primary !important; }

    &-big {
        display: inline-block;
        padding: 10px;
        background-color: $primaryLight;
        border-radius: 50%;
    }
}

#checkout-summary {
    transition: all 250ms ease-in-out 1s;

    &.hide { 
        height: 0px;
        display: none;
    }

    &.show { 
        height: auto;
        display: block;
    }
}


.checkout-steps {
    width: 100%;
    background-color: $primary;
    border-radius: 8px;

    & > div { 
        padding: 20px;
        align-items: center; 

        p { color: $white !important; }
    }

    .MuiAccordion-root { 
        background: $white;
        box-shadow: none !important; 
        padding: 0 !important;

        .MuiAccordionSummary-content.Mui-expanded { margin: 12px 0 !important; }

        .link, .btn { margin: 15px; }
    }

    p { margin: 0; }
}

.payment-types {
    .payment-type {
        border: 1px solid $white;
        border-radius: 10px;
        cursor: pointer;

        .type { 
            position: relative;
            padding: 24px 12px;
            background: $white;
            border-radius: 10px;
            box-shadow: 0px 0px 20px 0px $boxShadow;

            &.selected { 
                border: 1px solid $primary; 

                &::after {
                    content: url("../../../images/check.png");
                    position: absolute;
                    top: -9px;
                    right: -5px;
                    background-color: $primary;
                    padding: 6px 8px;
                    border-radius: 50%;
                }
            }
        }
    }
}

.transferencia {
    border: 3px solid $warning;
    border-radius: 16px;
    padding: 20px;

    h1 {
        font-size: 1rem;
        font-weight: 700;
    }

    p { font-size: 0.875rem; }

    table td { padding: 5px; }

    .account-number { 
        padding: 5px;
        cursor: pointer;
        background-color: $white;
    }
}

.presupuesto-issue {
    height: 58vh;

    .custom-card {
        width: 70%;
        padding: 30px;
        
        ul {
            list-style: square;

            li { list-style: square; }
        }
    }
}

.venta-success {
    height: 68vh;

    &__return-b2c {
        padding: 20px 0 0;
        @include flexRow(center, center);
        
        a {
            @include flexRow(center, center);
            gap: 6px;
            @include primaryText(14px);
            border: 1px solid $white;
            font-weight: 700;
            cursor: pointer;

            &:hover {
                transform: scale(1.02);
            }
        }

    }
}

.sended-receipt {
    height: 70vh;
}
  
.nav-logo-img {
    width: 156px;
    height: 20px;
}