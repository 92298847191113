@import '../../../../../styles/main.scss';

.shipping-options {
    @include flexCol(flex-start, flex-start);
    gap: 10px;
    border: 1px solid $boxShadow;
    border-radius: 8px;
    padding: 12px 10px;
    width: 100%;

    &__title {
        text-transform: uppercase;
    }

    &__container {
        @include flexRow(center, center);
        flex-wrap: wrap;
        gap: 14px;

        &--card {
            @include flexCol(center, center);
            gap: 10px;
            accent-color: $primary;
            border: 1px solid $boxShadow;
            border-radius: 8px;
            min-height: 110px;
            &.selected {
                border-color: $primary;
            }
            
            .shipping-container {
                padding: 10px;
                @include flexCol(center, space-between);
                text-transform: lowercase;
                height: 100%;
                min-height: 127px;
                min-width: 190px;
                
                &__subcontainer {
                    @include flexCol(center, center);
                    gap: 4px;
                    height: 100%;

                    &.subcontainer-text {
                        height: 100%;
                        min-height: 84px;
                    }

                    &.selected {
                        min-height: 107px;
                    }

                    span {
                        text-align: center;
                        font-size: 16px;
                        max-width: 176px;
    
                        img {
                            height: 16px;
                            margin-bottom: 5px;
                        }
    
                        &.shipping-time {
                            font-size: 12px;
                            text-transform: none;
                        }
    
                        &.recoge-cliente {
                            @include flexCol(center, center);
    
                            .recoge-title {
                                text-transform: uppercase;
                                font-size: 14px;
                                font-weight: 600;
                            }
    
                        }
                    }
                    
                    .shipping-container-spinner {
                        height: 22px;
                        position: relative;
    
                        .simple-spinner {
                            width: 15px;
                            height: 15px;
                            left: 0;
                            top: 50%;
                        }
    
                        .simple-spinner span {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            &--select {
                border-radius: 4px;
                text-transform: uppercase;
                font-size: 11px !important;
                background-color: $primary;
                color: $white;
                cursor: pointer;
                padding: 0 8px;
                font-weight: 300;
            }
            
        }
    }
}
