@import '../../../../styles/main.scss';

.form-input {
  position: relative !important;
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  &.piso, &.cp, &.cif-ec, &.b2b {
    width: 48% !important;
  }
  &.cif {
    width: 100% !important;
  }
  @include md {
    width: 48.5% !important;
    &.pais {
      width: 28% !important;
    }
    &.large {
      width: 69% !important;
    }
    &.piso, &.cp, &.cif {
      width: 48.5% !important;
    }
    &.name-fact-ec {
      width: 40% !important;
    }
    &.cif-ec {
      width: 36% !important;
    }
    &.b2b {
      width: 18% !important;
    }
  }

  @include lg {
    &.cif-ec {
      width: 30% !important;
    }
    &.b2b {
      width: 24% !important;
    }

  }

  @include xl {
    &.name, &.phone, &.email {
      width: 33% !important;
    }
    &.piso {
      width: 28% !important;
    }
    &.cp {
      width: 22% !important;
    }
    &.poblacion, &.area {
      width: 36.3% !important;
    }
  }

  &.autocomplete {
    input::placeholder, select::placeholder {
      opacity: 1 !important;
      font-size: 0.875rem !important;
      color: $primary !important;
    }

    input {
      padding-left: 10px !important;
    }
  }

  input, select {
    width: 100% !important;
    height: 45px !important;
    font-size: 14px !important;
    padding: 8px !important;
    outline: none !important;
    border: none !important;
    box-sizing: border-box !important;
    background-color: $white !important;
    box-shadow: 3px 3px 10px $boxShadow !important;
  }
  
  input::placeholder {
    font-size: 14px !important;
    color: $boxShadow !important;
    font-weight: 300 !important;
  }
  select::placeholder {
    opacity: 0 !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
  }
  
  label {
    position: absolute !important;
    left: 10px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    font-size: 0.875rem !important;
    color: $primary !important;
    letter-spacing: 1px !important;
    transition: 0.3s !important;
    font-weight: 400 !important;
  }
  input + label,
  input:focus + label,
  input:not(:placeholder-shown) + label,
  select:focus + label,
  select:not([value=""]) + label,
  select:valid + label {
    top: 0 !important;
    font-size: 0.8rem !important;
    color: $primary !important;
    background: $white !important;
    padding: 0px 6px !important;
    border-radius: 5px !important;
    font-weight: 600 !important;
  }
  
  input:focus,
  select:focus {
    border: 2px solid $primary !important;
    border-radius: 5px !important;
  }

  span {
    color: $danger !important;
    text-align: start !important;
    font-size: 0.8rem !important;
    padding-top: 5px;
  }

  select { 
    color: $fontSecondary !important;
  }
}
