@import '../base/color';

@mixin flexCol ($align, $justify){
  display: flex;
  flex-direction: column;
  align-items: $align;
  justify-content: $justify;
}

@mixin flexRow ($align, $justify) {
  display: flex;
  flex-direction: row;
  align-items: $align;
  justify-content: $justify;
}

@mixin grayText($font-size) {
  font-size: $font-size !important;
  color: $fontTertiary !important;
}

@mixin primaryText($font-size) {
  font-size: $font-size !important;
  color: $primary !important;
}

@mixin capitalize () {
  text-transform: lowercase;
      &::first-letter {
          text-transform: capitalize;
      }
}

@mixin btnEditDelete () {
  @include flexCol(center, center);
  height: 30px !important;
  width: 30px !important;
  @include grayText(14px);
  border: 1px solid $fontTertiary;
  border-radius: 6px;
  cursor: pointer;

  @include xl {
      width: 38px !important;
      height: 38px !important;
      border-radius: 12px;
      font-size: 18px !important;
  }
  
}

@mixin basicsCardDireccion () {
  @include flexRow(center, space-between);
  width: 100% !important;
  border: 1px solid $primary;
  border-radius: 8px;
  padding: 12px 10px;

  div {
      padding-right: 10px;
      cursor: pointer;
      h4 {
          font-size: 13px !important;
          color: $primary;
          font-weight: 600 !important;
          margin-bottom: 0 !important;
          padding-bottom: 3px;
      }
      
      h5 {
          font-size: 12px !important;
          font-weight: 500 !important;
      }
      
      p {
          font-size: 12px !important;
      }
  }
}

@mixin inputCheckbox () {
  height: 14px;
  width: 14px;    
  border-color: $primary;  
  cursor: pointer; 
  accent-color: $primary;
}

@mixin basicsAddNewButton () {
  @include flexRow(center, center);
  border: none;
  background-color: $white;
  box-shadow: 3px 3px 25px $boxShadow;
  padding: 12px 18px 12px 12px;
  border-radius: 8px;
  color: $primary;
  font-weight: 500;
  height: 40px;
  margin: 11px 0;
  cursor: pointer;

  span {
       @include flexRow(center, center);
       background-color: $primaryLight;
       margin-right: 16px; 
       width: 24px;
       height: 24px;
       border-radius: 6px;
       font-size: 01.1rem;
  };
}