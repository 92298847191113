@import '../../../../styles/base/color';
@import '../../../../styles/base/breakpoints';
@import '../../../../styles/mixins/mixins';

.carrito {
    @include flexCol(flex-start, flex-start);
    padding-bottom: 60px;

    @include lg {
        padding-bottom: 80px;
    }

    h1 {
        @include lgMax {
            font-size: 36px !important;
        }
    }

    & > div {
        width: 100%;
    }
    @include lg {
        & > div {
            @include flexRow(flex-start, space-between);
        }
        
    }
    
    @include xl {
        // padding: 110px 36px 0;
        max-width: 1400px;
        margin: auto;
    }

    &__detalle {
        // padding: 0 16px;

        &--title {
            @include primaryText(24px);
            // padding: 40px 0 28px;

            @include lg {
                font-size: 36px;
            }
        }

        &--entrega {
            @include grayText(12px);
            padding: 20px 0;

            @include lg {
                width: 370px;
            }
        }
    }

    &__envio, &__facturacion, &__shipping {
        @include flexCol(flex-start, flex-start);
        gap: 10px;
        padding: 16px 0;
        width: 100%;

        @include lg {
            width: 100%;
        }

        &--select-address {
            color: $danger !important;
            font-size: 14px !important;
        }

        &--search {
            width: 100%;
            position: relative;

            &--input {
                width: 100%;
            }
            
            &--icon {
                position: absolute;
                top: 30%;
                right: 15px;
            }
        }

        input[type=text]::placeholder {
            color: $fontSecondary !important;
        }
        
        &--direcciones {
            @include flexCol(flex-start, flex-start);
            width: 100%;
            gap: 10px;
            position: relative;
        }
        
        h2 {
            font-size: 22px !important;
            font-weight: 600 !important;
            color: $primary;
            padding-bottom: 10px;
        }

        &--title {
            @include flexCol(flex-start, flex-start)
        }

        &--add-address {
            width: 100%;
            @include flexRow(flex-end, flex-end);

            &--btn {
                @include basicsAddNewButton();

                &:active {
                    -webkit-transform: scale(0.98);
                    transform: scale(0.98);
                }
            }
        }
        
        &--otra {
            width: 100%;
            border: 1px solid $primary;
            border-radius: 8px;
            padding: 12px 10px;
            cursor: pointer;
            
            &.selected {
                background-color: $primaryLight;
            }
            
            h4 {
                @include primaryText(13px);
                margin-bottom: 0 !important;
            }
        }

        &--info {
            @include flexRow(flex-start, flex-start);
            font-size: 11px !important;
            padding-left: 3px;

        
            span {
                padding-right: 3px;
                @include primaryText(14px !important);
            }
        }
    }

    &__envio {
        @include md {
            padding-bottom: 16px !important;
        }
    }

    &__shipping {
        @include md {
            padding-top: 0px !important;
        }

        &--container {
            width: 100%;
            @include flexCol(flex-start, flex-start);
            gap: 10px;
        }
    }

    &__pedido {
        padding: 32px 0px 60px;
        width: 100%;

        @include lg {
            padding: 153px 0 0 24px;
            max-width: 400px;
            position: sticky;
            top: -120px;
        }
        
        @include custom(1300px) {
            max-width: 470px;
        }

        & > div {
            width: 100%;
            border-radius: 12px;
            box-shadow: 3px 3px 25px $boxShadow;
            padding: 20px 10px;

            @include lg {
                padding: 30px 20px;
            }
        }

        &--textarea {
            width: 100%;
            padding: 16px 6px;
            position: relative;
            
            textarea {
                width: 100%;
                height: 80px;
                border-radius: 8px;
                border-color: $primary;
                resize: none;
                padding: 16px 10px;
                outline-color: $primary;

                &::placeholder{
                    color: $primary;
                }
            }

        }

        &--submit {
            width: 100%;
            padding: 0 6px 9px;
            
            div {
                @include flexRow(flex-start, flex-start);
                gap: 5px;
                width: 100%;
                padding-bottom: 25px;

                input {
                    @include inputCheckbox();
                    margin-top: 1px;
                }

                p {
                    @include primaryText(13px);
                    padding-right: 40px;
                }
            }

            button {
                width: 100%;
                height: 36px;
                border-radius: 6px;
                border: none;
                background-color: $primary;
                color: $white;
                font-size: 13px;
                cursor: pointer;
                position: relative;

                
                &:active {
                    -webkit-transform: scale(0.99);
                    transform: scale(0.99);
                }
                
                &:disabled {
                   opacity: 0.8;
                   cursor: default;
                   -webkit-transform: none;
                   transform: none;
                }
            }
        }

    }
}