@import '../../../../../styles/main.scss';

.modal-close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    padding: 18px;
    justify-content: center !important;
    align-items: center !important;

    &__content {
        background: white;
        padding: 42px 20px;
        border-radius: 6px;
        text-align: center;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
        position: relative;

        transform: translateY(-180px); 
        opacity: 0; 
        transition: opacity 0.3s ease, transform 0.3s ease;

        &.visible {
            transform: translateY(0); 
            opacity: 1; 
        }

        &--close {
            font-size: 20px;
            color: $primary;
            position: absolute;
            right: 16px;
            top: 16px;
            cursor: pointer;
        }

        .contact-info {
            width: 100%;
            max-width: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 16px;

            &__container-input {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 2px;
                input,
                textarea {
                    width: 100%;
                }

                textarea {
                    resize: none;
                    height: 100px;
                }
            }

            &__container-btn {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
            }

            button {
              width: 100px;
              height: 42px;
              border-radius: 6px;
              border: none;
              color: $white;
              font-size: 16px;
              cursor: pointer;
              position: relative;

              &.cancel-button {
                background-color: $dangerBtn;

                &:hover {
                    background-color: $dangerBtnHover;
                }
              }

              &.submit-button {
                background-color: $primary;
                position: relative;

                .simple-spinner {
                    top: 44%;
                    left: 48%;
                }
                .simple-spinner span {
                    border-color: $primaryLight;
                }

                &:hover {
                    background-color: $primaryHover;
                }
              }

              &:active {
                  -webkit-transform: scale(0.99);
                  transform: scale(0.99);
              }
          }
        }
    }
}
